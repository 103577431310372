li a {
  text-decoration: none;
  color: #404040;
  padding: 26px;
  margin: 0 0;

}

li a:hover {
  color: #000000;
  background-color: lightgrey;
  cursor: pointer;
}

nav > a {
  margin-left: 90px;
}


@media screen and (max-width: 950px) {
  li > a   {
    /* margin-left: 5px; */
    display: none;
   }
}

@media screen and (max-width: 950px)  {
  nav > a {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin: 0 auto;
  }
}
.our-clients {
  float: left;
  width: 100%;
  margin-bottom: 40px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAGCAYAAAF1F8SnAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6N0JGRDYzQ0Q2NEVEMTFFM0IyQTRFOTlFQTAwMDlCNkUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6N0JGRDYzQ0U2NEVEMTFFM0IyQTRFOTlFQTAwMDlCNkUiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo3QkZENjNDQjY0RUQxMUUzQjJBNEU5OUVBMDAwOUI2RSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo3QkZENjNDQzY0RUQxMUUzQjJBNEU5OUVBMDAwOUI2RSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PltpRykAAAAcSURBVHjaYvDx8fkPEEAMIAAQQGAEEGBICCQBADafA8dB2gh/AAAAAElFTkSuQmCC) repeat-x center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 40px;
}

h5 {
  font-size: 26px;
  background-color:#272727;
  padding: 0 8px;
  font-weight: 500;
  text-transform: uppercase;
}
.address {
  text-decoration: none;
  font-size: 30px;
  color: #e96656;
}

.mail {
  font-size: 30px;
  color: #34d293;
  cursor: pointer;
}

.phone {
  font-size: 30px;
  color: #3ab0e2;
}

.text-footer {
  margin: 0;
  font-family: 'Gothic';
}

.author {
  font-size: 16px;
}

.author a {
  text-decoration: none;
  color: #939393;
  margin-left: 3px;
}

.author a:hover {
  text-decoration: underline;
}

.icon-map {
  max-width:40px;
  max-height:40px;
  width: auto;
  height: auto;
}

.icon-email {
  max-width:40px;
  max-height:40px;
  width: auto;
  height: auto;
}

.icon-tel {
  max-width:40px;
  max-height:40px;
  width: auto;
  height: auto;
}
header {
  background-image: url(/static/media/landingBlockchain.a0c42e9f.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: center;

}

aside {
  /* background-image: url('../images/landingImage2.jpg'); */
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}



@media screen and (max-width: 1000px) {
  header {
    background-position-y: 0px;
  }
}


body {
  margin: 0;
  overflow-x: hidden;
}
