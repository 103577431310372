header {
  background-image: url('../images/landingBlockchain.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: center;

}

aside {
  /* background-image: url('../images/landingImage2.jpg'); */
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}



@media screen and (max-width: 1000px) {
  header {
    background-position-y: 0px;
  }
}

