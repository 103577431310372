.address {
  text-decoration: none;
  font-size: 30px;
  color: #e96656;
}

.mail {
  font-size: 30px;
  color: #34d293;
  cursor: pointer;
}

.phone {
  font-size: 30px;
  color: #3ab0e2;
}

.text-footer {
  margin: 0;
  font-family: 'Gothic';
}

.author {
  font-size: 16px;
}

.author a {
  text-decoration: none;
  color: #939393;
  margin-left: 3px;
}

.author a:hover {
  text-decoration: underline;
}

.icon-map {
  max-width:40px;
  max-height:40px;
  width: auto;
  height: auto;
}

.icon-email {
  max-width:40px;
  max-height:40px;
  width: auto;
  height: auto;
}

.icon-tel {
  max-width:40px;
  max-height:40px;
  width: auto;
  height: auto;
}