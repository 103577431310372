.our-clients {
  float: left;
  width: 100%;
  margin-bottom: 40px;
  background: url('../images/lines.png') repeat-x center;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

h5 {
  font-size: 26px;
  background-color:#272727;
  padding: 0 8px;
  font-weight: 500;
  text-transform: uppercase;
}