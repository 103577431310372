li a {
  text-decoration: none;
  color: #404040;
  padding: 26px;
  margin: 0 0;

}

li a:hover {
  color: #000000;
  background-color: lightgrey;
  cursor: pointer;
}

nav > a {
  margin-left: 90px;
}


@media screen and (max-width: 950px) {
  li > a   {
    /* margin-left: 5px; */
    display: none;
   }
}

@media screen and (max-width: 950px)  {
  nav > a {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
}